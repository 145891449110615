<template>
    <div class="app-subscriber-horizontal-divider">
        <div class="app-subscriber-horizontal-divider__separator"></div>
        <div class="app-subscriber-horizontal-divider__text">{{ text }}</div>
        <div class="app-subscriber-horizontal-divider__separator"></div>
    </div>
</template>
<script>
export default {
    name: 'AppSubscriberHorizontalDivider',
    props: {
        text: {
            type: String,
            default: ''
        }
    }
};
</script>
<style lang="scss">
.app-subscriber-horizontal-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0 1.5rem 0;

    &__separator {
        border-top: 1px solid $silver;
        background: $silver;
        width: 100%;
        height: 0px;
    }
    &__text {
        color: $silver;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 130%;
        margin-left: 0.295rem;
        margin-right: 0.185rem;
    }
}
</style>
