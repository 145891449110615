/* eslint-disable vue/return-in-computed-property */
<template>
    <div class="app-subscriber-login-authenticate">
        <div>
            <app-subscriber-header :title="loginTitle" />
            <div class="app-subscriber-login-authenticate__email">{{ userEmail }}</div>
            <div class="app-subscriber-login-authenticate__contents">
                <!-- determine what content should be presented according to the stage of the login (currently, only the signin with google is available) -->
                <app-subscriber-password-login v-if="isUserLoginFlowPassword" :user-email="userEmail" />
                <div v-if="isUserLoginFlowSso">
                    <app-subscriber-horizontal-divider :text="dividerText" />
                    <app-subscriber-identity-provider-login :sso-login-url="ssoLoginUrl" />
                </div>
            </div>
        </div>
        <app-subscriber-footer />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import AppSubscriberHeader from '@/components/AppSubscriber/AppSubscriberHeader';
import AppSubscriberFooter from '@/components/AppSubscriber/AppSubscriberFooter';
import AppSubscriberIdentityProviderLogin from '@/components/AppSubscriber/Login/LoginActions/AppSubscriberIdentityProviderLogin';
import AppSubscriberPasswordLogin from '@/components/AppSubscriber/Login/LoginActions/AppSubscriberPasswordLogin';
import AppSubscriberHorizontalDivider from '@/components/AppSubscriber/common/AppSubscriberHorizontalDivider';

export default {
    name: 'AppSubscriberLoginAuthenticate',
    components: {
        AppSubscriberHeader,
        AppSubscriberFooter,
        AppSubscriberIdentityProviderLogin,
        AppSubscriberPasswordLogin,
        AppSubscriberHorizontalDivider
    },
    props: {
        didLoginAttemptFail: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('login', [
            'loginProcessInitialized',
            'isUserLoginFlowSso',
            'isUserLoginFlowPassword',
            'identityProviderType',
            'ssoLoginUrl',
            'userEmail'
        ]),
        loginTitle() {
            return langConsts.titleLogin;
        },
        dividerText() {
            return langConsts.dividerOr;
        }
    },
    async created() {
        if (this.loginProcessInitialized === false) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
            return;
        }
        if (this.isUserLoginFlowSso === false && this.isUserLoginFlowPassword === false) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
        }
    },
    methods: {
        ...mapActions('login', ['setErrorLogin'])
    }
};
</script>

<style lang="scss">
.app-subscriber-login-authenticate {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
    &__email {
        color: $outer-space;
        display: flex;
        justify-content: center;

        margin-top: -1.065rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.065rem;
    }
    &__contents {
        margin: 1.5rem 4.375rem 0 4.375rem;
    }
}
</style>
