<template>
    <div class="app-subscriber-identity-provider-login">
        <div>
            <app-subscriber-sso-login-button :sso-login-url="ssoLoginUrl" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import langConsts from '@/lang/en';

export default {
    name: 'AppSubscriberIdentityProviderLogin',
    components: {
        AppSubscriberSsoLoginButton: () => import('./SsoLogin/AppSubscriberSsoLoginButton')
    },
    computed: {
        ...mapState('login', ['ssoLoginUrl', 'loginProcessInitialized', 'isUserLoginFlowSso'])
    },
    async created() {
        if (this.loginProcessInitialized === false || this.isUserLoginFlowSso === false) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
        }
    },
    methods: {
        ...mapActions('login', ['setErrorLogin'])
    }
};
</script>
