<template>
    <div class="app-subscriber-password-login">
        <form method="post" novalidate>
            <app-subscriber-label :text="passwordLabel" />
            <tip-text-input
                v-model="localPassword"
                name="password"
                :placeholder="passwordPlaceholder"
                type="password"
                :invalid="shouldShowPasswordLoginError"
                :validation-message="passwordLoginError"
            />
            <tip-button id="passwordSubmitButton" :type="buttonType" :disabled="preventSubmit" :loading="localIsLoading" @click="passwordSubmit">
                {{ buttonTextNext }}
                <template v-slot:loading>
                    <font-awesome-icon class="fa-spin" :icon="['far', 'spinner']" />
                </template>
            </tip-button>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import router from '@/router';
import AppSubscriberLabel from '@/components/AppSubscriber/common/AppSubscriberLabel';

export default {
    name: 'AppSubscriberPasswordLogin',
    components: {
        AppSubscriberLabel
    },
    data() {
        return {
            localPassword: '',
            shouldShowPasswordLoginError: false,
            localIsLoading: false
        };
    },

    computed: {
        ...mapState('login', [
            'isLoading',
            'oidcReturnUrl',
            'loginProcessInitialized',
            'isUserLoginFlowPassword',
            'userEmail',
            'isUserPasswordValid',
            'redirectUrl',
            'showErrorLogin'
        ]),

        passwordPlaceholder() {
            return langConsts.titleEnterPassword;
        },
        buttonTextNext() {
            return langConsts.buttonTextNext;
        },
        passwordLoginError() {
            return langConsts.passwordLoginError;
        },
        preventSubmit() {
            return !this.localPassword || this.localIsLoading;
        },
        buttonType() {
            return this.localIsLoading ? 'secondary' : 'primary';
        },
        passwordLabel() {
            return langConsts.labelPassword;
        }
    },
    watch: {
        localPassword(password) {
            if (!password) {
                this.shouldShowPasswordLoginError = false;
            }
        }
    },
    async created() {
        if (this.loginProcessInitialized === false) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
            return;
        }
        if (this.isUserLoginFlowPassword === false) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
            return;
        }
        if (this.userEmail === null) {
            await this.setErrorLogin(langConsts.initializeLoginByUserFlowError);
            return;
        }
    },
    methods: {
        ...mapActions('login', ['authenticateUserPassword', 'setErrorLogin']),

        async passwordSubmit() {
            this.localIsLoading = true;
            this.shouldShowPasswordLoginError = false;

            await this.authenticateUserPassword({
                userEmail: this.userEmail,
                password: this.localPassword,
                oidcReturnUrl: this.oidcReturnUrl
            });

            if (!this.showErrorLogin) {
                if (this.isUserPasswordValid) {
                    window.location.assign(this.redirectUrl);
                } else {
                    this.shouldShowPasswordLoginError = true;
                    this.localIsLoading = false;
                }
            } else {
                this.localIsLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.app-subscriber-password-login {
    margin-bottom: 1.25rem;

    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
    &--loading {
        margin: auto;
    }
}
</style>
